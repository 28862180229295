<template>
  <ion-page class="page">
    <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
      <div style="text-align: center;">
        <p style="font-size: 24px;">Processing transaction, please wait.</p>
        <p>You will be redirected automatically.</p>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import StripeService from '@/shared/services/stripe';
import { openSubscribeSuccessModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
const { hydrateUser } = authStore();
const stripeService = ref(null) as any;
const polling = ref(null) as any;
const loading = ref(true);
const {
  public: { stripeKey },
} = useRuntimeConfig();

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});

onMounted(async () => {
  stripeService.value = StripeService.getInstance(stripeKey);
  const route = useRoute();
  const router = useRouter();
  let prevLoc = decodeURIComponent((route.query.from || '/?km_=1') as string);
  if (!prevLoc.includes('km_=1')) {
    if (prevLoc.includes('?')) prevLoc += '&km_=1';
    else prevLoc += '?km_=1';
  }
  const setupIntentId = route.query.setup_intent;
  const tier = route.query.tier as string;
  const setupIntentSecret = route.query.setup_intent_secret;
  const redirectStatus = route.query.redirect_status;
  const priceId = route.query.price_id;

  if (redirectStatus === 'succeeded') {
    let count = 0;
    polling.value = setInterval(async () => {
      try {
        const result = await stripeService.value.confirmSubscriptionCheckout(setupIntentId, priceId);
        if (result) {
          clearInterval(polling.value);
          if (!polling.value) return;
          polling.value = null;
          await hydrateUser();
          await openSubscribeSuccessModal(null, tier, result) as any;
          router.replace(prevLoc + '&success=1');
          return;
        } else if (result === false) {
          count += 1;
          if (count >= 40) {
            alert('Checkout could not be confirmed. Please use our support chat.')
            loading.value = false;
            clearInterval(polling.value);
            polling.value = null;
            router.replace(prevLoc + '&success=1');
          }
          return;
        }
      } catch (e) {
        console.error(e);
      }
    }, 3000);
  } else if (redirectStatus === 'canceled') {
    toast.show('Transaction canceled by user', 'nonative', 'primary');
    return router.replace(prevLoc);
  } else if (redirectStatus === 'failed') {
    toast.show('Transaction denied: You have not been charged', 'nonative', 'primary');
    router.replace(prevLoc);
    return;
  } else if (redirectStatus === 'pending') {
    router.replace(prevLoc);
    toast.show('Transaction was canceled', 'nonative', 'primary');
    return;
  }
});

</script>

<style lang="sass" scoped></style>
